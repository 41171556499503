import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';

import { NavController } from '@ionic/angular';

import { SettingsService } from '@ghale-ap/app/services/settings.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApGuard implements CanLoad {

  constructor (
    private readonly authenticationService: AuthenticationService,
    private readonly settingsService: SettingsService,
    private readonly navCtrl: NavController
  ) { }

  async canActivate (route: Route, segments: Array<UrlSegment>): Promise<boolean> {
    return this.check();
  }

  async canLoad (route: Route, segments: Array<UrlSegment>): Promise<boolean> {
    return this.check();
  }

  private async check (): Promise<boolean> {
    const amActive = await this.authenticationService.getActive;
    if (!amActive) {
      const enabled = await this.settingsService.enabled();
      if (!enabled) {
        this.navCtrl.navigateBack(['/signup']);
      }

      return enabled;
    } else {
      const amEnabled = await this.authenticationService.enabled();
      if (!amEnabled) {
        this.navCtrl.navigateBack(['/am']);
      }

      const enabled = await this.settingsService.enabled(false);
      if (!enabled) {
        this.navCtrl.navigateBack(['/signup']);
      }

      return enabled;
    }
  }
}
