import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';

import { NavController } from '@ionic/angular';

import { ApService } from '@ghale-ap/app/services/ap.service';
import { CapabilityService } from '@ghale-ap/app/services/capability.service';

@Injectable({
  providedIn: 'root'
})
export class CallGuard implements CanLoad {

  constructor (
    private readonly capabilityService: CapabilityService,
    private readonly apService: ApService,
    private readonly navCtrl: NavController
  ) {
  }

  async canLoad (route: Route, segments: Array<UrlSegment>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.capabilityService.capabilities.then((capabilities) => {
        if (this.apService.connected && capabilities) {
          resolve(true);
        } else {
          this.navCtrl.navigateBack(['/home']);

          resolve(false);
        }
      });
    });
  }
}
