import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { CordovaWebSocket } from '../definitions/cordova-websocket';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor (
    private readonly platform: Platform
  ) { }

  createWebSocket (url: string, token: string): WebSocket | CordovaWebSocket {
    if (this.platform.is('cordova')) {
      return new CordovaWebSocket(url, {
        Authorization: token
      });
    } else {
      const sourceHost = location.hostname.split('.');

      const targetHost = new URL(url).hostname.split('.').filter((s) => sourceHost.includes(s)).join('.');

      document.cookie = `Authorization=${token}; path=/; domain=${targetHost}; max-age=${5}`;

      return new WebSocket(url);
    }
  }
}
