import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LocaleGuard } from '@ghale/ngx-datetime';
import { MapsApiGuard } from '@ghale/ngx-location';

import { ApGuard } from '@ghale-ap/app/guards/ap.guard';
import { CallGuard } from '@ghale-ap/app/guards/call.guard';

const routes: Routes = [
  {
    canLoad: [
      ApGuard,
      MapsApiGuard,
      LocaleGuard
    ],
    canActivate: [
      ApGuard
    ],
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    path: 'home'
  },
  {
    canLoad: [
      ApGuard,
      CallGuard
    ],
    canActivate: [
      ApGuard
    ],
    loadChildren: () => import('./pages/call/call.module').then((m) => m.CallPageModule),
    path: 'call'
  },
  {
    loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
    path: 'signup'
  },
  {
    loadChildren: () => import('./pages/am/am.module').then((m) => m.AmPageModule),
    path: 'am'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  }
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ]
})
export class AppRoutingModule { }
