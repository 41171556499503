import { Component } from '@angular/core';

import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AlertController, Platform } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

declare let cordova: any;

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html'
})
export class AppComponent {

  loading;

  private readonly langs: Array<string> = [
    'es'
  ];

  private readonly messages: any = {};

  constructor (
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly alertController: AlertController,
    private readonly network: Network,
    private readonly translate: TranslateService
  ) {
    this.loading = true;
    this.useBrowserLang();
    // tslint:disable:no-string-literal
    this.translate.get('Continue').subscribe((m) => this.messages['Continue'] = m);
    this.translate.get('Internet connection is required').subscribe((m) => this.messages['Internet connection is required'] = m);
    this.translate.get('Activate it to continue').subscribe((m) => this.messages['Activate it to continue'] = m);
    this.initializeApp();
  }

  initializeApp (): void {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        if (this.platform.is('ios')) {
          (cordova.plugins).iosrtc.registerGlobals();
        }

        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }

      this.network.onDisconnect().subscribe(() => {
        // This handler pops when the user disables wifi but still has 3G in the devide, waiting some thime network type
        // gets the 3g type
        window.setTimeout(() => {
          if (this.network.type === 'none') {
            this.disconnectionHandler();
          }
        }, 3000);
      });

      this.loading = false;
    });
  }

  private disconnectionHandler (): void {
    this.alertController.create({
      backdropDismiss: false,
      buttons: [
        {
          cssClass: 'alert-primary',
          handler: () => {
            if (this.network.type === 'none') {
              this.disconnectionHandler();
            } else {
              try {
                // console.log(google);
              } catch (error) {
                this.loading = true;
                window.setTimeout(() => window.location.reload(), 500);
              }
            }
          },
          text: this.messages['Continue']
        }
      ],
      header: this.messages['Internet connection is required'],
      message: this.messages['Activate it to continue']
    })
      .then((alert) => {
        alert.present();
      });
  }

  private useBrowserLang (): void {
    const lang = this.translate.getBrowserLang();

    this.translate.addLangs(this.langs);

    if (this.langs.includes(lang)) {
      this.translate.use(lang);
    }
  }
}
